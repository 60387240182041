import { Component, Injector, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { BulkService } from 'app/services/bulk/bulk.service';
import { CustomLabelService } from 'app/services/custom-label/custom-label.service';
import { CustomWorkflowService } from 'app/services/custom-workflow/custom-workflow.service';
import { EventManager } from 'app/services/events/events.service';
import { FileService } from 'app/services/file/file.service';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';
import { LikeService } from 'app/services/like/like.service';
import {
  LocalStorageService,
  USER_INFO_KEY,
} from 'app/services/local-storage/local-storage.service';
import { RatingService } from 'app/services/rating/rating.service';
import { TagService } from 'app/services/tag/tag.service';
import { ToastService } from 'app/services/toast/toast.service';
import { VersioningService } from 'app/services/versioning/versioning.service';
import {
  File,
  FileErrorReason,
  FileLabel,
  GoogleRessourceRole,
  LabelFieldOptionType,
  LabelFieldType,
  LabelOption,
  LabelType,
  Rating,
  Tag,
  TagDatamart,
  UnprocessedEntity,
  UnprocessedEntityReason,
  UpdateTagsOperation,
  User,
  Version,
  WorkflowStepState,
  WorkflowTemplate,
} from 'app/types';
import { WorkflowStep } from 'app/types/custom-workflows.types';
import {
  getGoogleDriveFileId,
  isError,
  openURLInNewTab,
} from 'app/utils/utils';
import { MenuItem } from 'primeng/api';
import {
  catchError,
  forkJoin,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { CustomWorkflowComponent } from './dialogs/custom-workflow/custom-workflow.component';
import { DescriptionDialogComponent } from './dialogs/description/description-dialog.component';
import { FlagDialogComponent } from './dialogs/flag/flag-dialog.component';
import { PropertiesDialogComponent } from './dialogs/properties/properties-dialog.component';
import { RatingDialogsComponent } from './dialogs/rating/rating-dialogs.component';
import { SensitivityComponent } from './dialogs/sensitivity/sensitivity.component';
import { VersioningDialogComponent } from './dialogs/versioning/versioning-dialog.component';
import { WorkflowApprovalDialogComponent } from './dialogs/workflow-approval/workflow-approval-dialog.component';
import {
  DialogState,
  Heartbeat,
  LikeButtonData,
  Sections,
  SidePanel,
  SidePanelProperties,
  Status,
} from './side-panel.types';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrl: './side-panel.component.scss',
})
export class SidePanelComponent implements OnInit {
  readonly DialogState = DialogState;
  readonly Sections = Sections;
  readonly sidePanelStatus = Status;
  readonly dialogs = {
    [DialogState.DESCRIPTION]: {
      component: DescriptionDialogComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.PROPERTIES]: {
      component: PropertiesDialogComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.RATING]: {
      component: RatingDialogsComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.FLAG]: {
      component: FlagDialogComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.VERSIONING]: {
      component: VersioningDialogComponent,
      injector: Injector.create({
        providers: [
          {
            provide: 'OUTPUTS',
            useValue: {
              createDraft: (linkedGoogleUrl?: string) =>
                this.createNewVersion(linkedGoogleUrl),
            },
          },
        ],
      }),
    },
    [DialogState.WORKFLOW]: {
      component: WorkflowApprovalDialogComponent,
      injector: Injector.create({
        providers: [
          {
            provide: 'OUTPUTS',
            useValue: {
              approveDocument: (confidentiality?: string) =>
                this.approveWorkflowStep(-1, confidentiality),
            },
          },
        ],
      }),
    },
    [DialogState.SECRET]: {
      component: SensitivityComponent,
      injector: Injector.create({
        providers: [
          {
            provide: 'OUTPUTS',
            useValue: {
              applySensitivity: (confidentiality: string) =>
                this.updateFileConfidentialityOnSecret(confidentiality),
            },
          },
        ],
      }),
    },
    [DialogState.CUSTOMWORKFLOW]: {
      component: CustomWorkflowComponent,
      injector: Injector.create({
        providers: [],
      }),
    },
    [DialogState.NONE]: { component: null, injector: undefined },
  };

  readonly dialogsHeader = {
    [DialogState.DESCRIPTION]: 'Edit description',
    [DialogState.PROPERTIES]: 'Edit properties',
    [DialogState.RATING]: 'Select your rating',
    [DialogState.FLAG]: 'Why are you flagging this?',
    [DialogState.VERSIONING]: 'Add version',
    [DialogState.WORKFLOW]: 'Set confidentiality & Expiration date',
    [DialogState.SECRET]: 'Set file sensitivity',
    [DialogState.CUSTOMWORKFLOW]: 'Choose your workflow',
    [DialogState.NONE]: '',
  };

  sidePanel: SidePanel = this.getDefaultSidePanel();
  fileIdFromExtension: string | null = null;
  dialogToDisplay: DialogState = DialogState.NONE;
  isDocumentRatedByMe: boolean = false;
  splitButtonItems: MenuItem[] = [];
  likeButtonData?: LikeButtonData = undefined;
  lockedFileClass: string = '';
  hasRights: boolean = false;
  documentConfidentiality: string = '';
  lockedToasterVisible: boolean = true;
  workflowTemplates: WorkflowTemplate[] = [];

  constructor(
    private ratingService: RatingService,
    private tagService: TagService,
    private likeService: LikeService,
    private fileService: FileService,
    private bulkService: BulkService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private toastService: ToastService,
    private versioningService: VersioningService,
    private gdriveService: GdrivesService,
    private customLabelService: CustomLabelService,
    private customWorkflowService: CustomWorkflowService,
  ) {
    this.getUser$().subscribe();
  }

  ngOnInit(): void {
    this.sidePanel.status = Status.LOADING;
    // If after 2 seconds the docIDFromExtension is still null, we have no document selected
    setTimeout(() => {
      if (!this.fileIdFromExtension) {
        this.sidePanel.status = Status.UNKNOWN;
      }
    }, 2000);

    // Heartbeat answer extension
    EventManager.on('message', (event) => {
      switch (event.data.identifier) {
        case Heartbeat.EXTENSION_HEARTBEAT:
          window.parent.postMessage(
            {
              reason: 'heartbeat',
              identifier: Heartbeat.APP_HEARTBEAT,
              sentAt: Date.now(),
            },
            '*',
          );
          break;
        case Heartbeat.EXTENSION_DOCUMENT:
          if (
            ![this.sidePanel.file?.id, this.fileIdFromExtension].includes(
              event.data.docId,
            )
          )
            this.fetchAllData(event.data.docId);

          break;
        default:
          break;
      }
    });
  }

  getDefaultSidePanel(): SidePanel {
    return {
      connectedUser: undefined,
      status: Status.LOADING,
      loadingSections: [],
      tags: [],
      file: undefined,
      workflows: undefined,
      verifiedHistory: undefined,
      customLabels: [],
    };
  }

  handleFileErrors$(e: any) {
    if (isError(e?.error?.statusCode, 400)) {
      switch (e?.error?.message.reason) {
        case FileErrorReason.MY_DRIVE:
          this.sidePanel.status = Status.MY_DRIVE;
          break;
        case FileErrorReason.NOT_WATCHED_BY_OVERLAYER:
          this.sidePanel.status = Status.FILE_NOT_WATCHED;
          break;
        case FileErrorReason.NOT_FOUND:
          this.sidePanel.status = Status.NOT_FOUND;
          break;
        case FileErrorReason.IS_SECRET:
          this.sidePanel.status = Status.SECRET_FILE;
          break;
        default:
          this.sidePanel.status = Status.UNKNOWN;
      }
    } else {
      if (this.sidePanel.status !== Status.UNKNOWN) {
        this.toastService.error({
          summary: `Could not get file: ${e?.error?.message?.message}`,
        });
      }
      this.sidePanel.status = Status.UNKNOWN;
    }
    return of(null);
  }

  getUser$() {
    const user = this.localStorageService.getItem<User>(USER_INFO_KEY);
    return user
      ? of(user)
      : this.authService.getUser().pipe(
          map((r) => r.user),
          tap((user) => this.localStorageService.setItem(USER_INFO_KEY, user)),
          catchError(() => of(null)),
        );
  }

  getTags$() {
    return this.tagService.get().pipe(
      map((r) => r.tags),
      tap((tags) => (this.sidePanel.tags = tags)),
      map((tags) =>
        Injector.create({
          providers: [
            { provide: 'INPUTS', useValue: { tags: tags } },
            {
              provide: 'OUTPUTS',
              useValue: {
                cancelPropertiesDialog: () =>
                  this.toggleDialog(DialogState.NONE),
              },
            },
          ],
        }),
      ),
      tap(
        (injector) =>
          (this.dialogs[DialogState.PROPERTIES].injector = injector),
      ),
      catchError((error) => {
        this.toastService.error({ summary: 'Could not get tags' });
        return of(null);
      }),
    );
  }

  getCustomLabels$() {
    return this.customLabelService.getCustomLabels().pipe(
      tap((labels) =>
        labels.sort((a, b) =>
          a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }),
        ),
      ),
      tap((labels) => (this.sidePanel.customLabels = labels)),
      catchError(() => of(null)),
    );
  }

  getWorkflowTemplates$(documentId: string) {
    return this.customWorkflowService.getWorkflowTemplates(documentId).pipe(
      tap((templates) => (this.workflowTemplates = templates)),
      map((templates) =>
        Injector.create({
          providers: [
            {
              provide: 'INPUTS',
              useValue: { customWorkflowTemplates: templates, documentId },
            },
            {
              provide: 'OUTPUTS',
              useValue: {
                startWorkflow: (workflowTemplate: WorkflowTemplate) =>
                  this.startWorkflow(workflowTemplate),
              },
            },
          ],
        }),
      ),
      tap(
        (injector) =>
          (this.dialogs[DialogState.CUSTOMWORKFLOW].injector = injector),
      ),
      catchError((error) => {
        this.toastService.error({
          summary: 'Could not get custom workflows',
        });
        return of(null);
      }),
    );
  }

  getFileData$(documentId: string) {
    this.dialogToDisplay = DialogState.NONE;

    return this.fileService.get(documentId).pipe(
      tap(({ file, user_permissions }) => {
        this.sidePanel.file = file;
        this.sidePanel.status = Status.FILLED;
        this.lockedFileClass = file.is_locked ? 'locked-file' : '';
        this.initLikeButton();
        this.setFlagProvider();
        this.setDescriptionProvider(file?.datamart?.description);
        this.hasRights = !(
          user_permissions === GoogleRessourceRole.READER ||
          user_permissions === GoogleRessourceRole.COMMENTER
        );
        this.setPropertiesProvider(file);
        this.setRatingProvider(file?.ratings);
        this.setConfidentiality(file.labels);
        setTimeout(() => {
          this.lockedToasterVisible = false;
          this.lockedFileClass = '';
        }, 3000);
      }),
      map(({ file: { labels } }) =>
        labels?.reduce(
          (acc, label) =>
            acc ??
            label.selected_options.find(
              (option) =>
                option.type === LabelFieldOptionType.NEEDS_UPDATE_FLAGGED,
            ),
          <LabelFieldOptionType>null!,
        ),
      ),
      tap((optionType) => {
        this.setSplitItems(!!optionType);
      }),
      catchError(this.handleFileErrors$.bind(this)),
    );
  }

  getWorkflow$(documentId: string) {
    return this.customWorkflowService.getFileWorkflowInstances(documentId).pipe(
      tap(
        ({ workflow_instances }) =>
          (this.sidePanel.workflowInstances = workflow_instances.reverse()),
      ),
      catchError(() => of(null)),
    );
  }

  getFileHistory$(documentId: string) {
    return this.fileService.getFileHistory(documentId).pipe(
      tap(
        ({ history }) => (this.sidePanel.workflowFileVersionHistory = history),
      ),
      catchError(() => of(null)),
    );
  }

  getVerifiedHistory$(documentId: string) {
    return this.versioningService.getHistory(documentId).pipe(
      tap((response) =>
        Object.assign(response.history, {
          versions: this.orderVersioning(response.history.versions),
        }),
      ),
      tap((response) => (this.sidePanel.verifiedHistory = response)),
      catchError(() => of(null)),
    );
  }

  fetchAllData(fileId: string) {
    if (!fileId) {
      this.sidePanel.status = Status.UNKNOWN;
      this.sidePanel.file = undefined;
      this.fileIdFromExtension = null;
      return throwError(() => new Error('No file ID provided'));
    }

    this.sidePanel = {
      ...this.getDefaultSidePanel(),
      loadingSections: this.sidePanel.loadingSections,
    };
    this.fileIdFromExtension = fileId;

    const silence = (source: Observable<any>) =>
      source.pipe(catchError(() => of(null)));

    const dataLoader = forkJoin({
      user: this.getUser$().pipe(silence),
      tags: this.getTags$().pipe(silence),
      customLabels: this.getCustomLabels$().pipe(silence),
      fileData: this.getFileData$(fileId).pipe(silence),
      workflow: this.getWorkflow$(fileId).pipe(silence),
      history: this.getFileHistory$(fileId).pipe(silence),
      verifiedHistory: this.getVerifiedHistory$(fileId).pipe(silence),
      templates: this.getWorkflowTemplates$(fileId).pipe(silence),
    });

    const call = this.fileService.syncFile(fileId).pipe(
      catchError(this.handleFileErrors$.bind(this)),
      switchMap(() => dataLoader),
      shareReplay(),
      take(1),
    );

    call.subscribe();

    return call;
  }

  ensureStepAvailability(fileId: string, stepId: number) {
    return this.fetchAllData(fileId).pipe(
      map(() =>
        this.sidePanel.workflowInstances?.reduce(
          (acc: WorkflowStep, instance) =>
            acc ??
            instance.steps.find(
              (step) => step.state === WorkflowStepState.IN_PROGRESS,
            ),
          null!,
        ),
      ),
      map((step) => step?.id),
      map((id) => id === stepId),
      switchMap((matches) =>
        matches
          ? of(true)
          : throwError(
              () => new Error('File data was stale, action cancelled'),
            ),
      ),
      catchError((error) => {
        this.toastService.error({
          summary: error,
        });
        return throwError(() => error);
      }),
    );
  }

  setConfidentiality(labels?: FileLabel[]) {
    const type = labels?.find(
      (label) => label.label_field.type === LabelFieldType.FILE_CONFIDENTIALITY,
    )?.selected_options[0]?.type;

    switch (type) {
      case LabelFieldOptionType.FILE_CONFIDENTIALITY_PUBLIC:
        this.documentConfidentiality = 'Public';
        break;
      case LabelFieldOptionType.FILE_CONFIDENTIALITY_INTERNAL:
        this.documentConfidentiality = 'Internal';
        break;
      case LabelFieldOptionType.FILE_CONFIDENTIALITY_CONFIDENTIAL:
        this.documentConfidentiality = 'Confidential';
        break;
      default:
        this.documentConfidentiality = '';
    }
  }

  unsetFromLoadingSection(sections: Sections[]) {
    this.sidePanel.loadingSections = this.sidePanel.loadingSections.filter(
      (section) => !sections.includes(section),
    );
  }

  // SCREEN STATUS SECTION //
  watchDriveFromFileId() {
    if (!this.fileIdFromExtension) return;
    this.gdriveService
      .watchDriveFromFileId(this.fileIdFromExtension)
      .subscribe({
        next: () => {
          this.fetchAllData(this.fileIdFromExtension!);
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not add Overlayer to the shared drive',
          });
        },
      });
  }

  // DESCRIPTION SECTION //
  setDescriptionProvider(description?: string) {
    this.dialogs[DialogState.DESCRIPTION].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: { description: description } },
        {
          provide: 'OUTPUTS',
          useValue: {
            cancelDescriptionDialog: () => this.toggleDialog(DialogState.NONE),
            saveDescriptionDialog: (description: string) =>
              this.updateDocumentDescription(description),
          },
        },
      ],
    });
  }

  updateDocumentDescription(description?: string) {
    if (!this.sidePanel.file?.id || !description) return;
    this.toggleDialog(DialogState.NONE);
    this.bulkService
      .updateFilesProperties({
        file_ids: [this.sidePanel.file.id],
        document_type: this.sidePanel.file?.datamart?.document_type,
        language: this.sidePanel.file?.datamart?.language,
        description: description,
      })
      .subscribe({
        next: ({ failed }) => {
          this.sidePanel.loadingSections.push(Sections.DESCRIPTION);
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getFileData$(this.sidePanel.file.id).subscribe(() =>
              this.unsetFromLoadingSection([Sections.DESCRIPTION]),
            );
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not update file description',
          });
        },
      });
  }

  hasAScreenStatusToDisplay() {
    const displayableStatus = [
      this.sidePanelStatus.UNKNOWN,
      this.sidePanelStatus.MY_DRIVE,
      this.sidePanelStatus.FILE_NOT_WATCHED,
      this.sidePanelStatus.LOADING,
      this.sidePanelStatus.NOT_FOUND,
      this.sidePanelStatus.SECRET_FILE,
      this.sidePanelStatus.OVERLAYER_NOT_ENOUGH_PERMISSION,
    ];
    return displayableStatus.includes(this.sidePanel.status);
  }

  // PROPERTIES SECTION //
  setPropertiesProvider(file: File) {
    this.dialogs[DialogState.PROPERTIES].injector = Injector.create({
      providers: [
        {
          provide: 'INPUTS',
          useValue: {
            tags: this.sidePanel.tags,
            fileTags: file.tags
              ?.filter((tag) => !tag.ignored)
              .map((tag) => tag.tag),
            fileConfidentiality: this.documentConfidentiality,
            fileType: file?.datamart?.document_type,
            fileLanguage: file?.datamart?.language,
            customLabels: this.sidePanel.customLabels,
            fileCustomLabels: file.labels?.filter(
              (fileLabel) => fileLabel.label.type === LabelType.CUSTOM,
            ),
            hasRight: this.hasRights,
          },
        },
        {
          provide: 'OUTPUTS',
          useValue: {
            cancelPropertiesDialog: () => this.toggleDialog(DialogState.NONE),
            savePropertiesDialog: (properties: SidePanelProperties) =>
              this.updateDocumentProperties(properties),
          },
        },
      ],
    });
  }

  async updateDocumentProperties(properties: SidePanelProperties) {
    this.sidePanel.loadingSections.push(Sections.PROPERTIES);
    this.saveDocumentConfidentiality(properties.confidentiality);
    this.saveDocumentCustomLabels(properties.customPropertiesOptions);
    await this.saveDocumentTags(properties.tags);
    this.toggleDialog(DialogState.NONE);
  }

  ignoreSuggestedTag(tag?: TagDatamart) {
    if (!this.sidePanel.file?.id || !tag?.tag?.id) return;
    this.bulkService
      .updateFileTags({
        file_ids: [this.sidePanel.file.id],
        tag_ids: [],
        tags_to_ignore: [tag.tag.id],
        operation: UpdateTagsOperation.MERGE,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.sidePanel.loadingSections.push(Sections.PROPERTIES);
            forkJoin([
              this.getFileData$(this.sidePanel.file.id),
              this.getTags$(),
            ]).subscribe(() =>
              this.unsetFromLoadingSection([Sections.PROPERTIES]),
            );
          }
        },
        error: () => {
          this.toastService.error({
            summary: 'Could not remove suggested tag',
          });
        },
      });
  }

  updateFileConfidentialityOnSecret(confidentiality: string) {
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.status = Status.LOADING;
    this.saveDocumentConfidentiality(confidentiality);
  }

  saveDocumentConfidentiality(confidentiality: string) {
    const docId = this.sidePanel.file?.id ?? this.fileIdFromExtension;

    if (
      (!confidentiality || !this.sidePanel.file?.id) &&
      !this.fileIdFromExtension
    )
      return;
    this.bulkService
      .updateFilesConfidentiality({
        file_ids: [docId as string],
        confidentiality:
          (confidentiality?.toLocaleLowerCase?.() ?? '') || undefined,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (docId) {
            this.getFileData$(docId).subscribe();
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not set confidentiality.',
          });
        },
      });
  }

  bulkFailedNotEnoughPermission(
    failed: UnprocessedEntity<string>[] | undefined,
  ) {
    return failed?.some(
      (fail) => fail.reason === UnprocessedEntityReason.NOT_ENOUGH_PERMISSIONS,
    );
  }

  saveDocumentLanguageAndDocumentType(language: string, documentType: string) {
    if (!this.sidePanel.file?.id) return;
    if (!language && !documentType) return;

    this.bulkService
      .updateFilesProperties({
        file_ids: [this.sidePanel.file.id],
        language: language ?? this.sidePanel.file?.datamart?.language,
        document_type:
          (documentType ??
            this.sidePanel.file?.datamart?.document_type ??
            '') ||
          undefined,
        description: this.sidePanel.file?.datamart?.description,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            this.getFileData$(this.sidePanel.file.id).subscribe();
          }
        },
        error: (e) => {
          this.toastService.error({
            summary: 'Could not update file properties.',
          });
        },
      });
  }

  saveDocumentCustomLabels(customLabelsOptions: LabelOption[]) {
    if (!this.sidePanel.file?.id) return;

    const customLabelsOptionsMap = customLabelsOptions.reduce((map, { id }) => {
      const labelOptionId = id.split('+', 2).join('+');
      if (!map.has(labelOptionId)) {
        map.set(labelOptionId, []);
      }
      map.get(labelOptionId)?.push(id);
      return map;
    }, new Map<string, string[]>());

    const selectedCustomLabels = Array.from(
      customLabelsOptionsMap,
      ([field_id, option_ids]) => ({
        field_id,
        option_ids,
      }),
    );

    this.bulkService
      .updateFilesCustomLabels([this.sidePanel.file.id], selectedCustomLabels)
      .subscribe({
        next: () => {},
        error: (error) => {
          this.toastService.error({
            summary: 'Could not update custom properties.',
          });
        },
      });
  }

  async saveDocumentTags(tags: Tag[]) {
    if (!this.sidePanel.file?.id) return;
    /** Update the document tags */
    const tagsIds: number[] = [];
    const tagsPromises: Promise<any>[] = [];

    this.sidePanel.loadingSections.push(Sections.PROPERTIES);

    /** Loop over the tags to create the new ones and add all ids in the array */
    tags.forEach((tag: Tag) => {
      if (tag.id !== -1) {
        tagsIds.push(tag.id);
      } else {
        tagsPromises.push(
          lastValueFrom(this.tagService.create(tag.label)).then((response) => {
            if (response.tag?.id) {
              tagsIds.push(response.tag.id);
            }
          }),
        );
      }
    });
    await Promise.all(tagsPromises);
    this.bulkService
      .updateFileTags({
        file_ids: [this.sidePanel.file.id],
        tag_ids: tagsIds,
        tags_to_ignore: [],
        operation: UpdateTagsOperation.REPLACE,
      })
      .subscribe({
        next: ({ failed }) => {
          if (this.bulkFailedNotEnoughPermission(failed)) {
            this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
          } else if (this.sidePanel.file?.id) {
            forkJoin([
              this.getFileData$(this.sidePanel.file.id),
              this.getTags$(),
            ]).subscribe(() =>
              this.unsetFromLoadingSection([Sections.PROPERTIES]),
            );
          }
        },
        error: (e: any) => {
          this.toastService.error({
            summary: 'Could not set tags',
          });
        },
      });
  }

  // RATING SECTION //
  setRatingProvider(ratings?: Rating[]) {
    let userRating = 0;

    ratings?.forEach((rating) => {
      if (
        rating.rated_by.primary_email ===
        this.sidePanel.connectedUser?.primary_email
      ) {
        userRating = rating.rating;
        this.isDocumentRatedByMe = true;
      }
    });
    this.dialogs[DialogState.RATING].injector = Injector.create({
      providers: [
        { provide: 'INPUTS', useValue: { rating: userRating } },
        {
          provide: 'OUTPUTS',
          useValue: {
            applyRating: (rating: number) => this.updateRating(rating),
          },
        },
      ],
    });
  }

  updateRating(rating: number) {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(Sections.RATINGS);
    this.sidePanel.loadingSections.push(Sections.TITLE);
    this.ratingService
      .rateFile({
        file_id: this.sidePanel.file?.id,
        rating: rating,
      })
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            this.getFileData$(this.sidePanel.file.id).subscribe(() =>
              this.unsetFromLoadingSection([Sections.RATINGS, Sections.TITLE]),
            );
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not rate document',
          });
        },
      });
  }

  // WORKFLOW SECTION //
  startWorkflow(workflowTemplate: WorkflowTemplate) {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.WORKFLOW);
    this.toggleDialog(DialogState.NONE);
    this.customWorkflowService
      .startWorkflowInstance(this.sidePanel.file?.id, workflowTemplate.id)
      .subscribe({
        next: (response) => {
          if (this.sidePanel.file?.id) {
            forkJoin([
              this.getFileData$(this.sidePanel.file.id),
              this.getWorkflow$(this.sidePanel.file.id),
            ]).subscribe(() =>
              this.unsetFromLoadingSection([Sections.WORKFLOW]),
            );
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not start workflow',
          });
        },
      });
  }

  getIndexOfCurrentStep(): number {
    const step = this.sidePanel.workflowInstances?.reduce(
      (acc, w) =>
        acc ?? w.steps.find((s) => s.state === WorkflowStepState.IN_PROGRESS),
      null! as WorkflowStep,
    );
    if (!step?.id) throw new Error('Unable to find step');
    return step.id;
  }

  approveWorkflowStep(stepId: number, confidentiality?: string) {
    let calculatedStepId = stepId;

    if (!this.sidePanel.file?.id) return;

    if (stepId === -1) {
      calculatedStepId = this.getIndexOfCurrentStep();
    }
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push(
      Sections.WORKFLOW,
      Sections.LABELS,
      Sections.WORKFLOW_FILE_VERSIONS,
      Sections.VERSIONING,
    );

    this.ensureStepAvailability(this.sidePanel.file.id, calculatedStepId)
      .pipe(
        switchMap(() =>
          this.customWorkflowService.approveWorkflowStep(
            calculatedStepId,
            confidentiality,
          ),
        ),
      )
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            forkJoin([
              this.getWorkflow$(this.sidePanel.file.id),
              this.getFileData$(this.sidePanel.file.id),
              this.getFileHistory$(this.sidePanel.file.id),
              this.getVerifiedHistory$(this.sidePanel.file.id),
            ]).subscribe(() => (this.sidePanel.loadingSections = []));
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not approve workflow step',
          });
        },
      });
  }

  rejectWorkflow(stepId: number) {
    if (!this.sidePanel.file?.id) return;

    this.sidePanel.loadingSections.push(
      Sections.WORKFLOW,
      Sections.LABELS,
      Sections.WORKFLOW_FILE_VERSIONS,
      Sections.VERSIONING,
    );

    this.ensureStepAvailability(this.sidePanel.file.id, stepId)
      .pipe(
        switchMap(() => this.customWorkflowService.rejectWorkflowStep(stepId)),
      )
      .subscribe({
        next: () => {
          if (this.sidePanel.file?.id) {
            forkJoin([
              this.getWorkflow$(this.sidePanel.file.id),
              this.getFileData$(this.sidePanel.file.id),
            ]).subscribe(() => (this.sidePanel.loadingSections = []));
          }
        },
        error: () => {
          this.sidePanel.loadingSections = [];
          this.toastService.error({
            summary: 'Could not reject workflow step',
          });
        },
      });
  }

  previewFile(id: string) {
    window.open(`https://docs.google.com/open?id=${id}`, '_blank')?.focus();
  }

  // VERSIONING SECTION //
  createNewVersion(linkedGoogleUrl?: string) {
    if (linkedGoogleUrl) {
      this.createDraftFromExistingFile(linkedGoogleUrl);
    } else {
      this.createDraftFromCurrentFile();
    }
  }

  createDraftFromCurrentFile() {
    if (!this.sidePanel.file?.id) return;
    this.toggleDialog(DialogState.NONE);
    this.sidePanel.loadingSections.push();
    this.versioningService.createDraft(this.sidePanel.file?.id).subscribe({
      next: (response) => {
        if (this.sidePanel.file?.id) {
          this.getVerifiedHistory$(this.sidePanel.file.id).subscribe(() =>
            this.unsetFromLoadingSection([Sections.VERSIONING]),
          );
        }
        openURLInNewTab(`https://docs.google.com/open?id=${response.file?.id}`);
      },
      error: () => {
        this.toastService.error({
          summary: 'Could not create a new version',
        });
        this.sidePanel.loadingSections = [];
      },
    });
  }

  createDraftFromExistingFile(linkedGoogleUrl: string) {
    if (!this.sidePanel.file?.id) return;

    const fileId = getGoogleDriveFileId(linkedGoogleUrl);
    if (fileId === null) {
      this.toastService.error({
        summary: 'The given url is not a google drive url',
      });
      return;
    }
    if (this.sidePanel?.verifiedHistory?.history?.id) {
      this.toggleDialog(DialogState.NONE);
      this.sidePanel.loadingSections.push(Sections.VERSIONING);
      this.versioningService
        .attachFileToHistory(this.sidePanel.verifiedHistory.history.id, fileId)
        .subscribe({
          next: (response) => {
            if (this.sidePanel.file?.id) {
              this.getVerifiedHistory$(this.sidePanel.file.id).subscribe(() =>
                this.unsetFromLoadingSection([Sections.VERSIONING]),
              );
            }
            openURLInNewTab(linkedGoogleUrl);
          },
          error: (e: any) => {
            this.toastService.error({
              summary: 'Could not link file to a new version',
            });
          },
        });
    } else {
      this.toastService.error({
        summary: 'Could not link file to a new version',
      });
    }
  }

  // LIKE SECTION //
  likeDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LIKE);
    this.likeService.like(this.sidePanel.file?.id).subscribe({
      next: () => {
        if (this.sidePanel.file?.id)
          this.getFileData$(this.sidePanel.file.id).subscribe(() =>
            this.unsetFromLoadingSection([Sections.LIKE]),
          );
      },
      error: () => {
        this.sidePanel.loadingSections = [];
        this.toastService.error({
          summary: 'Could not like document',
        });
      },
    });
  }

  dislikeDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LIKE);
    this.likeService.unlike(this.sidePanel.file?.id).subscribe({
      next: () => {
        if (this.sidePanel.file?.id)
          this.getFileData$(this.sidePanel.file.id).subscribe(() =>
            this.unsetFromLoadingSection([Sections.LIKE]),
          );
      },
      error: () => {
        this.sidePanel.loadingSections = [];
        this.toastService.error({
          summary: 'Could not remove like on document',
        });
      },
    });
  }

  // FLAG SECTION //
  setFlagProvider() {
    this.dialogs[DialogState.FLAG].injector = Injector.create({
      providers: [
        {
          provide: 'OUTPUTS',
          useValue: {
            cancelFlagDialog: () => this.toggleDialog(DialogState.NONE),
            flagDocument: (reason: string) => this.flagDocument(reason),
          },
        },
      ],
    });
  }

  flagDocument(reason: string) {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.toggleDialog(DialogState.NONE);
    this.bulkService.flagFiles([this.sidePanel.file?.id], reason).subscribe({
      next: ({ failed }) => {
        if (this.bulkFailedNotEnoughPermission(failed)) {
          this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
        } else if (this.sidePanel.file?.id) {
          this.getFileData$(this.sidePanel.file.id).subscribe(() =>
            this.unsetFromLoadingSection([Sections.LABELS]),
          );
        }
      },
      error: (e: any) => {
        this.toastService.error({
          summary: 'Could not flag document',
        });
      },
    });
  }

  unflagDocument() {
    if (!this.sidePanel.file?.id) return;
    this.sidePanel.loadingSections.push(Sections.LABELS);
    this.bulkService.unflagFiles([this.sidePanel.file?.id]).subscribe({
      next: ({ failed }) => {
        if (this.bulkFailedNotEnoughPermission(failed)) {
          this.sidePanel.status = Status.OVERLAYER_NOT_ENOUGH_PERMISSION;
        } else if (this.sidePanel.file?.id) {
          this.getFileData$(this.sidePanel.file.id).subscribe(() =>
            this.unsetFromLoadingSection([Sections.LABELS]),
          );
        }
      },
      error: (e: any) => {
        this.toastService.error({
          summary: 'Could not unflag document',
        });
      },
    });
  }

  // LOCKED FILE SECTION //
  unlockFile() {
    if (!this.sidePanel.file?.id) return;
    this.fileService.unlockFile(this.sidePanel.file?.id).subscribe({
      next: () => {
        if (this.sidePanel.file?.id) {
          this.fetchAllData(this.sidePanel.file.id);
        }
        this.toastService.success({
          summary: 'File has been unlocked',
        });
      },
      error: (e) => {
        if (e.error.statusCode === 409) {
          if (this.sidePanel.file?.id) {
            this.fetchAllData(this.sidePanel.file.id);
          }
          this.toastService.success({
            summary: 'File has been unlocked',
          });
        } else {
          this.toastService.error({
            summary: 'Could not unlock file',
          });
        }
      },
    });
  }

  // FOOTER BUTTONS SECTION //
  initLikeButton() {
    this.likeButtonData = {
      likedByMe: false,
      likesCount: this.sidePanel.file?.likes?.length ?? 0,
      onClick: () => {
        if (this.sidePanel.file?.likes?.length) {
          this.dislikeDocument();
        } else {
          this.likeDocument();
        }
      },
    };

    this.sidePanel.file?.likes?.forEach((like) => {
      if (
        like?.liked_by?.primary_email ===
          this.sidePanel.connectedUser?.primary_email &&
        this.likeButtonData
      ) {
        this.likeButtonData.likedByMe = true;
      }
    });
  }

  isRatedByTheUser() {
    return this.sidePanel.file?.ratings?.find((rating) => {
      return (
        rating.rated_by.primary_email ===
        this.sidePanel.connectedUser?.primary_email
      );
    })
      ? true
      : false;
  }

  setSplitItems(flagged: boolean) {
    this.splitButtonItems = [
      {
        label: this.isRatedByTheUser() ? 'Update my rating' : 'Add a rating',
        command: () => {
          this.toggleDialog(DialogState.RATING);
        },
      },
      {
        label: 'Edit description',
        command: () => {
          this.toggleDialog(DialogState.DESCRIPTION);
        },
      },
    ];
    if (this.hasRights && !this.sidePanel.file?.is_locked) {
      this.splitButtonItems.unshift({
        label: flagged ? 'Unflag content' : 'Flag content',
        command: () => {
          flagged ? this.unflagDocument() : this.toggleDialog(DialogState.FLAG);
        },
      });
    }
    this.fileService.canUnverifyFile(this.sidePanel.file?.id!).subscribe({
      next: ({ can_unverify }) => {
        if (can_unverify) {
          this.splitButtonItems = [
            {
              label: 'Unverify document',
              command: () => {
                this.sidePanel.loadingSections.push(Sections.LABELS);
                this.unverifyFile(this.sidePanel.file?.id!);
              },
            },
            ...this.splitButtonItems,
          ];
        }
      },
    });
  }

  unverifyFile(fileId: string) {
    this.fileService.unverifyFile(fileId).subscribe({
      next: () => {
        this.toastService.success({
          summary: 'Document has been unverified',
        });
        this.getFileData$(fileId).subscribe();
      },
      error: () => {
        this.toastService.error({
          summary: 'Could not unverify document',
        });
      },
    });
  }

  orderVersioning(versions: Version[]): Version[] {
    return versions.sort((a, b) => {
      if (!a.version) return -1;
      if (!b.version) return 1;
      return b.version - a.version;
    });
  }

  toggleDialog(dialogState: DialogState) {
    this.dialogToDisplay = dialogState;
  }
}
