import { Injectable } from '@angular/core';
import { TrinityService } from 'app/services/trinity/trinity.service';
import {
  WorkflowCanBeStartedResponse,
  WorkflowCanContinueResponse,
  WorkflowInstanceResponse,
  WorkflowInstancesResponse,
  WorkflowTemplateResponse,
  WorkflowTemplatesResponse,
} from 'app/types';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomWorkflowService {
  // TODO commonize with other service
  private readonly endpointTemplate: string = '/workflow-template';
  private readonly endpointInstance: string = '/workflow-instance';
  private readonly endpointStep: string = '/workflow-step';

  constructor(private trinityService: TrinityService) {}

  getWorkflowTemplates(
    documentId: string,
  ): Observable<WorkflowTemplatesResponse> {
    return this.trinityService.get<WorkflowTemplatesResponse>(
      `${this.endpointTemplate}/filtered/${documentId}`,
      {
        authorized: true,
      },
    );
  }

  getWorkflowTemplate(
    workflowTemplateId: string,
  ): Observable<WorkflowTemplateResponse> {
    return this.trinityService.get<WorkflowTemplateResponse>(
      `${this.endpointTemplate}/${workflowTemplateId}`,
      {
        authorized: true,
      },
    );
  }

  getFileWorkflowInstances(
    fileId: string,
  ): Observable<WorkflowInstancesResponse> {
    return this.trinityService.get<WorkflowInstancesResponse>(
      `${this.endpointInstance}/${fileId}`,
      {
        authorized: true,
      },
    );
  }

  startWorkflowInstance(
    fileId: string,
    workflowTemplateId: number,
  ): Observable<WorkflowInstanceResponse> {
    const body = {
      file_id: fileId,
      template_id: workflowTemplateId,
    };
    return this.trinityService.post<WorkflowInstanceResponse>(
      this.endpointInstance,
      {
        body: body,
        authorized: true,
      },
    );
  }

  canStartWorkflow(id: number, fileId: string) {
    return this.trinityService.get<WorkflowCanBeStartedResponse>(
      `${this.endpointTemplate}/${id}/permissions/${fileId}`,
      {
        authorized: true,
      },
    );
  }

  canContinueWorkflowStep(
    id?: number,
  ): Observable<WorkflowCanContinueResponse> {
    if (!id)
      return of({
        can_approve: false,
        can_reject: false,
        can_acknowledge: false,
      });
    else
      return this.trinityService.get(`${this.endpointStep}/${id}/permissions`, {
        authorized: true,
      });
  }

  rejectWorkflowStep(id: number) {
    return this.trinityService.put(`${this.endpointStep}/${id}/apply`, {
      body: { action: 'REJECT_DOCUMENT_VERIFICATION' },
      authorized: true,
    });
  }

  approveWorkflowStep(id: number, confidentiality?: string) {
    const body = {
      action: 'APPROVE_DOCUMENT_VERIFICATION',
      confidentiality: confidentiality?.toLowerCase(),
    };

    return this.trinityService.put(`${this.endpointStep}/${id}/apply`, {
      body: body,
      authorized: true,
    });
  }
}
