import { Injectable } from '@angular/core';
import { TrinityService } from 'app/services/trinity/trinity.service';
import {
  canUnverifyFileResponse,
  FileHistoryResponse,
  FileResponse,
  FilesResponse,
  LabelType,
  WorkflowResponse,
  WorkflowsResponse,
} from 'app/types';
import { Observable } from 'rxjs';

interface SetTagsOptions {
  /** The ID of the file to set the tags for. */
  file_id: string;

  /** The tags to set (set to empty array to remove all tags). */
  ids: number[];
}

interface SetLabelsOptions {
  /** The ID of the file to set the labels for. */
  file_id: string;

  /** The labels to set (set to empty array to remove all labels). */
  labels: LabelType[];
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly endpoint: string = '/file';

  constructor(private trinityService: TrinityService) {}

  getFlaggedDocuments(): Observable<FilesResponse> {
    return this.trinityService.get<FilesResponse>(`${this.endpoint}/flagged`, {
      authorized: true,
    });
  }

  get(file_id: string): Observable<FileResponse> {
    return this.trinityService.get<FileResponse>(`/drive/file/${file_id}`, {
      authorized: true,
    });
  }

  getWorkflowFiles(state: string): Observable<WorkflowsResponse> {
    return this.trinityService.get<WorkflowsResponse>(`/workflow`, {
      params: {
        state: state,
      },
      authorized: true,
    });
  }

  getWorkflows(
    file_id: string,
    next_page_token?: number,
  ): Observable<WorkflowsResponse> {
    const params: { [key: string]: number } = {
      limit: 50,
    };

    if (next_page_token) {
      params['offset'] = next_page_token;
    }
    return this.trinityService.get<WorkflowsResponse>(
      `${this.endpoint}/workflows/${file_id}`,
      {
        params: params,
        authorized: true,
      },
    );
  }

  requestedByMe(offset: number = 0) {
    return this.trinityService.get<FilesResponse>(
      `${this.endpoint}/requested`,
      {
        params: {
          limit: 50,
          offset: offset,
          verified_within_days: 7,
        },
        authorized: true,
      },
    );
  }

  getExpiringFiles(days: number = 21) {
    return this.trinityService.get<FilesResponse>(
      `${this.endpoint}/expire_soon`,
      {
        params: {
          days: days,
        },
        authorized: true,
      },
    );
  }

  setFileAsApproved(
    file_id: string,
    confidentiality: string,
    expire_at: string | null,
  ): Observable<WorkflowResponse> {
    const body = expire_at
      ? { confidentiality: confidentiality, expire_at: expire_at }
      : { confidentiality: confidentiality };
    return this.trinityService.post<WorkflowResponse>(
      `${this.endpoint}/approve/${file_id}`,
      {
        body: body,
        authorized: true,
      },
    );
  }

  unlockFile(file_id: string): Observable<FileResponse> {
    return this.trinityService.delete<FileResponse>(
      `${this.endpoint}/lock/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  getFileHistory(file_id: string): Observable<FileHistoryResponse> {
    return this.trinityService.get<FileHistoryResponse>(
      `${this.endpoint}/history/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  canUnverifyFile(fileId: string): Observable<canUnverifyFileResponse> {
    return this.trinityService.get<canUnverifyFileResponse>(
      `${this.endpoint}/unverify/${fileId}/permissions`,
      {
        authorized: true,
      },
    );
  }

  unverifyFile(fileId: string) {
    return this.trinityService.put(`${this.endpoint}/unverify/${fileId}`, {
      authorized: true,
    });
  }

  syncFile(fileId: string) {
    return this.trinityService.get(`/drive${this.endpoint}/${fileId}/sync`, {
      authorized: true,
    });
  }
}
