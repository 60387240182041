<div class="apply-properties">
  <p-autoComplete
    [suggestions]="searchedTags"
    optionLabel="label"
    [multiple]="true"
    (completeMethod)="searchTags($event)"
    [(ngModel)]="tagsInput"
    [virtualScroll]="true"
    [virtualScrollItemSize]="30"
    placeholder="Select tags"
  >
    <ng-template let-tag pTemplate="item">
      @if (tag.id !== -1) {
        <div class="tag-selector">
          <i class="pi pi-tags"></i>
          <span>{{ tag.label }}</span>
        </div>
      } @else {
        <div class="tag-selector blue">
          <i class="pi pi-plus-circle"></i>
          <span>{{ tag.label }}</span>
        </div>
      }
    </ng-template>
  </p-autoComplete>
  <p-dropdown
    [options]="confidentialityOptions"
    [(ngModel)]="confidentialitiesInput"
    placeholder="Confidentiality"
    class="dropdown-width"
  />
  @if (hasRight) {
    @for (customLabel of customLabels; track customLabel) {
      @if (customLabel.options?.length) {
        @if (customLabel.allowMultiSelect) {
          <p-multiSelect
            [options]="customLabel.options"
            [(ngModel)]="selectedCustomLabelsOptions[$index]"
            optionLabel="name"
            [filter]="true"
            [maxSelectedLabels]="1"
            [virtualScroll]="true"
            [virtualScrollItemSize]="30"
            [placeholder]="customLabel.label_field.name"
          >
          <ng-template let-custom pTemplate="item">
            <div class="tag-selector">
              <i class="pi pi-tags"></i>
              <span>{{ custom?.name }}</span>
            </div>
          </ng-template>
          </p-multiSelect>
        } @else {
          <p-dropdown
            [options]="customLabel.options"
            [filter]="true"
            optionLabel="name"
            [(ngModel)]="selectedCustomLabelsOptions[$index][0]"
            [placeholder]="customLabel.label_field.name"
            [showClear]="true"
            class="dropdown-width"
          >
            <ng-template let-custom pTemplate="item">
              <div class="tag-selector">
                <i class="pi pi-tags"></i>
                <span>{{ custom?.name }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        }
      }
    }
  }
  <diV class="properties-buttons">
    <p-button
      label="Cancel"
      severity="secondary"
      (mousedown)="cancelPropertiesDialog()"
    />
    <p-button
      label="Save changes"
      (mousedown)="
        savePropertiesDialog({
          tags: tagsInput,
          confidentiality: confidentialitiesInput,
          documentType: documentTypeInput,
          language: languageInput,
          customPropertiesOptions: getSelectedCustomLabels(),
        })
      "
    />
  </diV>
</div>
