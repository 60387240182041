@let workflowInstances = $workflowInstances();
@let runningStep = $runningStep();
@let loading = $loading();
@let fileId = $fileId();
@let authorizations = $authorizations();

@if (loading) {
  <div class="skeleton-activity">
    <p-skeleton height="70px" class="skeleton-activity" styleClass="mb-2" />
  </div>
} @else {
  <div class="activity-container">
    <span class="span-header">Activity</span>

    @for (instance of workflowInstances; track instance.id) {
      @let state = workflowStatesMap[instance.state];

      <p-panel
        toggleable
        [collapsed]="instance.state !== Workflow.WAITING"
        collapseIcon="pi pi-chevron-down"
        expandIcon="pi pi-chevron-up"
      >
        <ng-template pTemplate="header">
          <div class="activity-row">
            @if (instance.state === Workflow.VALIDATED) {
              <p-tag
                class="worflow-status"
                [icon]="state.icon"
                [class]="state.class"
              />
            } @else {
              <p-tag
                class="worflow-status"
                [icon]="state.icon"
                [class]="state.class"
              />
            }

            @if (instance.pdf_file_id) {
              <div
                class="pdf-export"
                role="button"
                (click)="drivePreview.emit(instance.pdf_file_id)"
              >
                <i class="pi pi-file-pdf"></i>
              </div>
            }

            <div
              class="workflow-name"
              [pTooltip]="instance.template.name"
              tooltipPosition="top"
            >
              {{ instance.template.name }}
            </div>

            @if (instance.finished_at) {
              <div class="date">
                {{ instance.finished_at | date: 'dd/MM/yy' }}
              </div>
            }
          </div>
        </ng-template>

        <div class="timeline">
          @for (step of instance.steps; track step.id) {
            @let stepState = workflowStepStatesMap[step.state];
            <div class="bullet">
              <span></span>
            </div>
            <div class="content">
              <p-tag
                class="worflow-status"
                [pTooltip]="stepState.value"
                tooltipPosition="right"
                [icon]="stepState.icon"
                [class]="stepState.class"
              />
              <span class="step-name">
                {{ step.template.name }}
              </span>

              @if (step.state_modified_at) {
                <div class="date">
                  {{ step.state_modified_at | date: 'dd/MM/yy' }}
                </div>
              }
            </div>
          }

          @if (fileId && instance.file_id && instance.file_id !== fileId) {
            <div class="bullet">
              <span></span>
            </div>
            <div class="content">
              <p-tag class="worflow-status workflow-info" icon="pi pi-eye" />
              <span
                class="step-link"
                role="link"
                (click)="drivePreview.emit(instance.file_id)"
              >
                Link to original file
              </span>
            </div>
          }
        </div>
      </p-panel>

      @if (instance.state !== Workflow.REJECTED) {
        @if (runningStep && instance.steps.includes(runningStep)) {
          <div class="actions">
            <p-button
              label="Reject"
              [outlined]="true"
              size="small"
              [disabled]="!authorizations?.can_reject"
              (onClick)="rejectWorkflowEvent.emit(runningStep.id)"
            />
            <p-button
              label="Approve"
              size="small"
              [disabled]="!authorizations?.can_approve"
              (onClick)="approveWorkflow(instance, runningStep)"
            />
          </div>
        }
      }
    }

    @if (!runningStep) {
      <p-button
        label="Start workflow"
        size="small"
        (onClick)="startWorkflowEvent.emit()"
      />
    }
  </div>
}
